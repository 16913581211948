/** @jsx jsx */
import { DUIButton, DUIText } from "@alch/ui";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { Box, jsx } from "theme-ui";
import { StringParam, useQueryParam } from "use-query-params";

import ErrorView from "../components/ErrorView";
import MasterLayout from "../components/MasterLayout";
import Section from "../components/Section";
import { fullWidthSx } from "../components/styles";
import {
  canAutoverifyEmail,
  confirmEmailChange,
  ConfirmEmailChangeResult,
} from "../http/endpoints";
import { Loading, useWaitForLoad } from "../util/loading";

export default function ConfirmEmailPage(): ReactElement {
  const [token] = useQueryParam("token", StringParam);
  /**
   * Part of a mechanism to defend against email services that automatically
   * crawl all links contained in emails. See `canAutoverifyEmail()` in
   * `handlers.ts` for an explanation.
   */
  const [canAutoverifyResult, setCanAutoverifyResult] = useState(
    Loading.inProgress<boolean>(),
  );
  const [confirmResult, setConfirmResult] = useState(
    Loading.inProgress<ConfirmEmailChangeResult>(),
  );
  const waitForLoad = useWaitForLoad();

  const confirmEmail = useCallback(() => {
    if (!token) {
      return;
    }
    waitForLoad(confirmEmailChange({ token }), setConfirmResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Legacy (turned on exhaustive-deps for new code)
  }, [token]);

  useEffect(() => {
    if (!token) {
      window.location.href = "/";
    }
    waitForLoad(canAutoverifyEmail(), setCanAutoverifyResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Legacy (turned on exhaustive-deps for new code)
  }, []);

  useEffect(() => {
    if (!canAutoverifyResult.value) {
      return;
    }
    confirmEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Legacy (turned on exhaustive-deps for new code)
  }, [canAutoverifyResult.value]);

  const shouldDisplayManualConfirm =
    canAutoverifyResult.error || canAutoverifyResult.value === false;

  return (
    <MasterLayout title="Change Email">
      {shouldDisplayManualConfirm && (
        <Section centered={true}>
          <DUIText size="2xl">Verify email change</DUIText>
          <DUIText
            size="md"
            mt={2}
            sx={{
              maxWidth: "300px",
              color: "gray.4",
            }}
          >
            Click Confirm to change your email.
          </DUIText>
          <DUIButton mt={5} sx={fullWidthSx} size="lg" onClick={confirmEmail}>
            Confirm
          </DUIButton>
        </Section>
      )}
      {confirmResult.error && (
        <ErrorView
          title="Failed to change email"
          message={confirmResult.error.message ?? "An unknown error occurred."}
        />
      )}
      {confirmResult.hasValue && (
        <Section>
          <Box>
            <DUIText>Your email has been updated!</DUIText>
            <div>
              <a href={confirmResult.value.redirectUrl || "/"}>Continue</a>
            </div>
          </Box>
        </Section>
      )}
    </MasterLayout>
  );
}
